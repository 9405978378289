import { withDataConverter } from '@beef/react';
import { compose } from '@beef/utils';
import React from 'react';
import { connect } from 'react-redux';

import store, { headerStore2024 } from 'store';

import { getUserData, getUserInfo } from '../../../../store/feature/userInfo/selectors';
import { PaymentWidget as _PaymentWidget } from './PaymentWidget';
import { setInputState } from './components/Input/store';
import { withPaymentWidget } from './enhancers/withPaymentWidget';
import { composeRoot } from './root';
import { setContent } from './store';
import { TPaymentWidgetProps } from './types';
import { mapHeaderUserInfoToUserInfo } from './utils';
import { findFirst } from './utils.common';

const { converter, onStateChange } = composeRoot();

export const PaymentWidget = compose<React.FC<Partial<TPaymentWidgetProps>>>(
  connect((state) => ({
    userInfo: getUserData(state),
  })),
  withPaymentWidget({
    setContent,
    setInputState,
    getInitialCtn: () => {
      const user = headerStore2024().getState()?.header?.common?.auth?.user;

      const userInfo = findFirst([
        user && mapHeaderUserInfoToUserInfo(user),
        getUserInfo(store.getState()),
      ]);

      return findFirst([userInfo.type === 0 && userInfo.ctn, userInfo.fttb]);
    },
    onStateChange,
  }),
  withDataConverter(converter),
)(_PaymentWidget);
