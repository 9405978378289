import { connect } from 'react-redux';

import { selectForHomeSection } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    titleNavigation: selectForHomeSection(state).titleNavigation,
    inlineBanner: {
      duration: selectForHomeSection(state).inlineBanner.duration,
      slides: selectForHomeSection(state).data.inlineBanner.banners,
    },
    beelineTV: selectForHomeSection(state).beelineTV,
    devices: selectForHomeSection(state).devices,
    anchor: selectForHomeSection(state).anchor,
    ethernetCardBlock: selectForHomeSection(state).ethernetCardBlock,
  };
};

export const connector = connect(mapStateToProps);
