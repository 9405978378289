import { connect } from 'react-redux';

import { selectMainSlider } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    duration: selectMainSlider(state).carousel.duration,
    slides: selectMainSlider(state).data.carousel.banners,
  };
};

export const connector = connect(mapStateToProps);
