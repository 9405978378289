import { isEmptyValue } from 'utils/isEmptyValue';

import { formatPhoneWithDefaultPrefix } from './format-string';

const patterns = [
  ['%%AAAABAB', '_-__-_-_', '%%AAAA%%%'],
  ['%%AAAAA%%', '_-____-_'],
  ['%AAAABCBC', '-___-_-_'],
  ['%%AAABBCC', '_-__-_-_'],
  ['%AAABBAAA', '-__-_-__', '%%AA%%AAA'],
  ['%AAABBCCC', '-__-_-__'],
  ['%AAAABBB%', '-___-__-'],
  ['%AAAA%BBB', '-___--__'],
  ['%%%AAABAB', '_--_-_-_', '%%%AAA%A%'],
  ['%%%AAAABB', '_--___-_'],
  ['%%%AABBCC', '_--_-_-_'],
  ['%%%AAAAA%', '_--____-'],
  ['%%%AAABBB', '_--__-__'],
  ['%%%A0B0C0', '_--_-_-_'],
  ['%%%%%AAAA', '_-__-___'],
  ['%%%%%AABB', '_-__-_-_'],
  ['%%%%%AAA%', '_-__-__-'],
  ['%%%%%ABAB', '_-__-_-_'],
  ['%%%%%ABBA', '_-__-_-_'],
  ['%%%%%%AAA', '_-__--__'],
  ['%%%ABCABC', '_--__-__'],
  ['%%ABABAB%', '_-_-_-_-'],
  ['%%ABCABC%', '_-__-__-'],
  ['%%ABC%ABC', '_-__--__'],
  ['%%%AA%AA%', '_--_--_-'],
  ['%%ABABBAB', '_-______', '%%ABAB%AB'],
  ['%%AAA%%%%', '_-__-_-_'],
  ['%%%AAA%%%', '_--__-__'],
  ['%%%%AAA%%', '_-_-__-_'],
  ['%%%%%AAA%', '__-_-__-'],
  ['%%AAAA%%%', '_-_-_-__'],
  ['%%%AAAA%%', '__-_-_-_'],
  ['%%%%AAAA%', '_-_-_-_-'],
  ['%%%AA%BB%', '_--_-_-_'],
  ['%%%AA%%BB', '__-_-_-_'],
  ['%%AA%%BB%', '_-_-_-__'],
  ['%%AA%%%BB', '_-_-__-_'],
  ['%%AABBCC%', '_-_-_-__'],
  ['%%AA%BBCC', '_-_--_-_'],
  ['%%AABB%CC', '_-_-_--_'],
  ['%AAAA%%%%', '_-__-_-_'],
  ['%AABBB%%%', '__-__-__'],
  ['AABBB%%%%', '_-__-_-_'],
  ['AABBBCC%%', '_-__-_-_'],
  ['AABB%%%%%', '_-_--_-_'],
  ['%%AABB%%%', '_-_-_-__'],
];

const reduceValues = (number, sign) => number.map((n, i) => +n + (sign === '-' ? -i : i));

const checkRow = (digits) => {
  let startIndex = 0;
  let index = 0;

  for (; index < digits.length - 1; index += 1) {
    if (digits[index] !== digits[index + 1]) {
      if (index - startIndex > 4) return [startIndex, index];
      startIndex = index + 1;
    }
  }
  return index - startIndex > 4 ? [startIndex, index] : null;
};

const checkIfSorted = (number) => {
  const digits = number.split('');
  return checkRow(reduceValues(digits, '-')) || checkRow(reduceValues(digits, '+'));
};

const getSortedPattern = (range) =>
  Array(9)
    .fill(0)
    .reduce((acc, _, index) => acc + (index >= range[0] && index <= range[1] ? 'A' : '%'), '');

const getSortedDivider = (pattern) => {
  let i = 1;
  let started = false;
  let finished = false;
  let divider = '';

  for (; i <= pattern.length; i += 1) {
    if (pattern[i] === 'A' && !started) {
      started = true;
      divider += '-';
    } else if (pattern[i] === '%' && !finished && started) {
      finished = true;
      divider += '-';
    } else {
      divider += '_';
    }
  }
  return divider;
};

const getHighlightedNumber = (number, pattern, highlight) =>
  ['%'].concat(pattern[0].split('')).reduce(
    (acc, item, index) =>
      `${acc}${
        // eslint-disable-next-line no-nested-ternary
        item === '%' || (highlight && highlight[index - 1] !== item) ?
          pattern[1][index - 1] === '-' ?
            'S'
          : 'N'
        : pattern[1][index - 1] === '-' ? 'V'
        : 'Y'
      }${number[index]}`,
    '',
  );

const matches = (number) => (pattern) => {
  const digits = {};
  return !pattern[0].split('').some((item, index) => {
    if (item === '%') return false;
    if (item !== 'A' && item !== 'B' && item !== 'C') {
      return item !== number[index];
    }
    if (!digits[item]) {
      digits[item] = number[index];
    }
    return digits[item] !== number[index];
  });
};

export const formatNumber = (number, numberMasks) => {
  const cutNumber = number.slice(-9);
  const patternIndex =
    numberMasks ?
      numberMasks.findIndex(matches(cutNumber))
    : patterns.findIndex(matches(cutNumber));
  let pattern = numberMasks ? numberMasks[patternIndex] : patterns[patternIndex];
  if (patternIndex === -1) {
    const sortedRange = checkIfSorted(cutNumber);
    const sortedPattern = sortedRange ? getSortedPattern(sortedRange) : '%%%%%%%%%';
    const divider = sortedRange ? getSortedDivider(sortedPattern) : '_-__-_-_';

    pattern = [sortedPattern, divider];
  }

  return getHighlightedNumber(number, pattern);
};

export const beautifyNumbers = (numbers, numberMasks) =>
  numbers ?
    numbers.map((category) => ({
      ...category,
      numbers: category.numbers.map((number) => ({
        ...number,
        formattedValue: number.formattedValue || formatNumber(number.value, numberMasks),
      })),
    }))
  : null;

export const mapPricelessNumbers = (numbers) =>
  numbers.map((category) => ({
    ...category,
    numbers: category.numbers.map((number) => ({
      ...number,
      formattedValue: number.formattedValue || formatNumber(number.value),
      featureCode: number.featureCode,
      price: category.price,
      discountPercent: category.discountPercent,
      priceWithDiscount: category.priceWithDiscount,
    })),
  }));

export const numberToHtml = ({
  number,
  color = '#ffe02b',
  highlightOnly = false,
  highlightCssProp = 'background',
  styleConfig = {
    rightSide: null,
    leftSide: null,
  },
  additionalStylesOptionsForRedesign2024,
}) => {
  if (!number.formattedValue || !number.formattedValue.length) {
    return `+7 ${number.value.slice(0, 3)} ${number.value.slice(3)}`;
  }
  if (!color) {
    return formatPhoneWithDefaultPrefix(number.value);
  }

  const string = number.formattedValue.slice(0, 20);
  let result = '+7 ';
  let key;
  let nextKey;
  let digit;
  let styles;

  for (let i = 0; i < string.length - 1; i += 2) {
    key = string[i];
    nextKey = string[i + 2];
    digit = string[i + 1];

    if (highlightOnly) {
      if (i === 6) result += ' ';
      if (key === 'Y' || key === 'V') {
        result += `<span>${digit}</span>`;
      } else {
        result += digit;
      }
      // eslint-disable-next-line no-continue
      continue;
    }

    styles = [];

    if (
      key === 'S' ||
      key === 'V' ||
      (key === 'N' && nextKey === 'Y') ||
      (key === 'Y' && nextKey === 'N') ||
      (key === 'N' && i === 4)
    ) {
      styles.push('margin-right:4px');
    }
    if (key === 'V' || key === 'Y') {
      styles.push(`${highlightCssProp}:${color}`);

      if (string[i - 2] !== 'Y') {
        // redesign2024
        if (!isEmptyValue(additionalStylesOptionsForRedesign2024)) {
          styles.push(additionalStylesOptionsForRedesign2024?.begin.join('; '));
        }

        styles.push('padding-left:2px');
        if (styleConfig.leftSide) {
          styles.push(styleConfig.leftSide);
        }
      }
      if ((key === 'Y' && (!nextKey || nextKey === 'N')) || key === 'V') {
        styles.push('padding-right:2px');
        // redesign2024
        if (!isEmptyValue(additionalStylesOptionsForRedesign2024)) {
          styles.push(additionalStylesOptionsForRedesign2024?.end.join('; '));
        }
        if (styleConfig.rightSide) {
          styles.push(styleConfig.rightSide);
        }
      }
    }

    result = `${result}<span${
      styles.length === 0 ? '' : ` style="${styles.join(';')}"`
    } tabIndex="-1">${digit}</span>`;
  }
  return result;
};
