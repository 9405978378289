import { connect } from 'react-redux';

import { selectForFamilySection } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    titleNavigation: selectForFamilySection(state).titleNavigation,
    cardsBlock: selectForFamilySection(state).cardsBlock,
    devices: selectForFamilySection(state).devices,
    inlineBanner: {
      duration: selectForFamilySection(state).inlineBanner.duration,
      slides: selectForFamilySection(state).data.inlineBanner.banners,
    },
    anchor: selectForFamilySection(state).anchor,
  };
};

export const connector = connect(mapStateToProps);
