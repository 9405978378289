import { connect } from 'react-redux';

import { selectForYouSection } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    titleNavigation: selectForYouSection(state).titleNavigation,
    inlineBanner: {
      duration: selectForYouSection(state).inlineBanner.duration,
      slides: selectForYouSection(state).data.inlineBanner.banners,
    },
    newCustomerBlock: selectForYouSection(state).newCustomerBlock,
    paymentBlock: selectForYouSection(state).paymentBlock,
    anchor: selectForYouSection(state).anchor,
    brands: selectForYouSection(state).brands,
    devices: selectForYouSection(state).devices,
  };
};

export const connector = connect(mapStateToProps);
