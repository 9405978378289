import React from 'react';
import { captureMessage } from '@sentry/react';
import { detectIncognito } from 'detectincognitojs';

function composeMessage(isPrivate: boolean): string;
function composeMessage(isPrivate: boolean, browserName: string): string;
function composeMessage(isPrivate: boolean, browserName?: string): string {
  if (browserName) {
    return `${browserName} - ${isPrivate ? 'Private' : 'Regular'} Browsing window`;
  }

  return `Usage Incognito Browsing Mode = ${isPrivate ? 'yes' : 'no'}`;
}

export const useDetectIncognitoMode = (): void => {
  React.useEffect(() => {
    if (window.detectIncognitoMode) {
      detectIncognito()
        .then(({ isPrivate, browserName }) => {
          window.detectIncognitoModeСonclusion = composeMessage(isPrivate, browserName);
          captureMessage(composeMessage(isPrivate), 'info');
        })
        .catch((reason) => {
          window.detectIncognitoModeСonclusion = reason;
        });
    }
  }, []);
};
